/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.87
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LucorApiModelsResultadosCiencia } from './lucorApiModelsResultadosCiencia';
import { SexoEnum } from './sexoEnum';
import { AnexoAvaliado } from './anexoAvaliado';

/**
 *
 */

export interface LucorApiModelsAvaliado {
    codigo: string;
    nome?: string;
    email?: string;
    sexo?: SexoEnum;
    dataSolicitacaoPreenchimento?: Date;
    dataConclusaoPreenchimento?: Date;
    avaliacaoId?: number;
    resultadosCiencias?: Array<LucorApiModelsResultadosCiencia>;
    anexos?: Array<AnexoAvaliado>;
    id?: number;
    createdAt?: Date;
    updatedAt?: Date;
    selected: boolean;
}

