/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.110
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LucorApiModelsDetailsAnexo } from './lucorApiModelsDetailsAnexo';
import { TipoMensagem } from './tipoMensagem';

export interface LucorApiModelsDetailsMensagemEmail { 
    id?: number;
    createdAt?: Date;
    updatedAt?: Date;
    nomeUsuario?: string;
    avaliacaoId?: number;
    pessoaJuridicaId?: number;
    dataHoraEnviado?: Date;
    readonly destinatarios?: Array<string>;
    listaDestinatarios?: string;
    readonly cc?: Array<string>;
    listaCc?: string;
    readonly cco?: Array<string>;
    listaCco?: string;
    assunto?: string;
    corpo?: string;
    corpoEhHtml?: boolean;
    listaAnexos?: Array<LucorApiModelsDetailsAnexo>;
    tipoMensagem?: TipoMensagem;
}

