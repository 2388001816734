/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LucorApiModelsDetailsRanking } from './lucorApiModelsDetailsRanking';


/**
 * 
 */
export interface LucorApiModelsDetailsAderenciaCiencia { 
    /**
     * Gets or Sets CienciaId
     */
    cienciaId?: number;
    /**
     * Gets or Sets Ciencia
     */
    ciencia?: string;
    /**
     * Gets or Sets Rankings
     */
    rankings?: Array<LucorApiModelsDetailsRanking>;
}
