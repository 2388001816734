/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.110
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 import { TipoMensagem } from './tipoMensagem';

 export interface LucorApiModelsUpdUpdMensagemEmail { 
    avaliacaoId?: number;
    destinatarios?: Array<string>;
    readonly listaDestinatarios?: string;
    assunto: string;
    corpo: string;
    corpoEhHtml: boolean;
    cc?: Array<string>;
    readonly listaCc?: string;
    cco?: Array<string>;
    readonly listaCco?: string;
    tipoMensagem?: TipoMensagem;
 }