/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.52
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LucorApiModelsAvaliado } from './lucorApiModelsAvaliado';


/**
 *
 */
export interface LucorApiModelsAvaliacao {
    nomeArquivoOrigem: string;
    hashArquivoOrigem?: string;
    servicoId?: number;
    clienteId?: number;
    dataImportacao?: Date;
    avaliados?: Array<LucorApiModelsAvaliado>;
    id?: number;
    createdAt?: Date;
    updatedAt?: Date;
}
