/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.104
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TipoAcao } from './tipoAcao';

export interface LogAuditoria { 
    quemAcessou?: string;
    nomeUsuario?: string;
    razaoSocial?: string;
    quandoAcessou?: Date;
    tipoAcao?: TipoAcao;
    acaoUsuario?: string;
    tipoEntidade?: string;
    idEntidade?: string;
    descricaoEntidade?: string;
    pessoaJuridicaId?: number;
}