/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.45
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LucorApiModelsFator } from './lucorApiModelsFator';


/**
 *
 */

export interface LucorApiModelsCiencia {
    /**
     * Gets or Sets Nome
     */
    nome: string;
    /**
     * Gets or Sets Descricao
     */
    descricao?: string;
    /**
     * Gets or Sets Fatores
     */
    fatores?: Array<LucorApiModelsFator>;
    /**
     * Gets or Sets Id
     */
    id?: number;
    /**
     * Gets or Sets CreatedAt
     */
    createdAt?: Date;
    /**
     * Gets or Sets UpdatedAt
     */
    updatedAt?: Date;
}
