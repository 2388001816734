/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.87
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TipoAnexo } from './tipoAnexo';

export interface AnexoAvaliado { 
    /**
     * Gets or Sets Id
     */
    id?: number;
    /**
     * Gets or Sets CreatedAt
     */
    createdAt?: Date;
    /**
     * Gets or Sets UpdatedAt
     */
    updatedAt?: Date;
    /**
     * Gets or Sets AvaliacaoId
     */
    avaliacaoId: number;
    /**
     * Gets or Sets AvaliadoId
     */
    avaliadoId: number;
    /**
     * Gets or Sets Nome
     */
    nome?: string;
    /**
     * Gets or Sets Email
     */
    email?: string;
    tipo?: TipoAnexo;
    fileName?: string;
    filePath?: string;
    fileMimeType?: string;
    bucketName?: string;
    signedUrl?: string;
}