/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.104
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LogAuditoria } from './logAuditoria';

export interface ResultadoPesquisaLogsAuditoria { 
    paginaAtual?: number;
    itensPorPagina?: number;
    totalLogs?: number;
    totalPaginas?: number;
    logs?: Array<LogAuditoria>;
}