/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.52
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LucorApiModelsDetailsAvaliadoFavorito } from './lucorApiModelsDetailsAvaliadoFavorito';


/**
 *
 */
export interface LucorApiModelsDetailsListagemFavoritos {
    /**
     * Gets or Sets Nome
     */
    nome: string;
    /**
     * Gets or Sets PessoaJuridicaId
     */
    pessoaJuridicaId?: number;
    /**
     * Gets or Sets CargoId
     */
    cargoId?: number;
    /**
     * Gets or Sets TituloCargo
     */
    tituloCargo?: string;
    /**
     * Gets or Sets Avaliados
     */
    avaliados?: Array<LucorApiModelsDetailsAvaliadoFavorito>;
    /**
     * Gets or Sets Id
     */
    id?: number;
    /**
     * Gets or Sets CreatedAt
     */
    createdAt?: Date;
    /**
     * Gets or Sets UpdatedAt
     */
    updatedAt?: Date;
}
