/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.88
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LucorApiModelsDetailsAderenciaCiencia } from './lucorApiModelsDetailsAderenciaCiencia';
import { LucorApiModelsDetailsRanking } from './lucorApiModelsDetailsRanking';


/**
 *
 */
export interface LucorApiModelsDetailsPessoaAderente {
    avaliacaoId: number;
    avaliadoId: number;
    nome?: string;
    email?: string;
    ranking?: LucorApiModelsDetailsRanking;
    idListagemFavoritos?: Array<number>;
    idAvaliadoListagemFavoritos?: Array<number>;
    aderenciaCiencias?: Array<LucorApiModelsDetailsAderenciaCiencia>;
    curriculo?: string;
    relatorioComportamental?: string;
    outrosDocs?: Array<string>;
}
