/**
 * Swagger Lucor
 * Swagger Lucor
 *
 * OpenAPI spec version: 1.0.87
 * Contact: roger.cazangi@hotmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AnexoAvaliado } from './anexoAvaliado';
import { FalhaAnexoAvaliado } from './falhaAnexoAvaliado';

export interface ResultadoAnexosAvaliadosLote { 
    /**
     * Gets or Sets AnexosIncluidos
     */
    anexosIncluidos: Array<AnexoAvaliado>;
    /**
     * Gets or Sets Falhas
     */
    falhas: Array<FalhaAnexoAvaliado>;
}